<template>
  <div class="index">
    <div class="header">
      <div class="logo-box" @click="goHome">
        <div class="logo">
          <img src="../../../assets/images/logo1.png" alt="">
        </div>
        <div class="name">教学管理云平台2.0</div>
      </div>
    </div>
    <div class="main">
      <!--      // 侧边栏-->
      <aside v-loading="questionListLoading">
        <div class="search-box">
          <el-input
            v-model="questionKeyWord"
            placeholder="请输入题目名称"
            :suffix-icon="questionKeyWord?'none':'el-icon-search'"
            clearable
            maxlength="20"
            @change="onRefresh"
          />
          <div>
            <div class="select-box">
              <el-select v-model="knowledgeLabel" style="width: 145px" clearable multiple collapse-tags placeholder="知识点" @change="onRefresh">
                <el-option
                    v-for="i in knowledgeList"
                    :key="i.id"
                    :label="i.name"
                    :value="i.id"
                />
              </el-select>
              <el-select v-model="enterpriseLabel" style="width: 145px" clearable multiple collapse-tags placeholder="企业" @change="onRefresh">
                <el-option
                    v-for="item in enterpriseList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
            <div class="select-box">
              <el-select v-model="difficultyFactor" style="width: 300px;" clearable placeholder="难度" @change="onRefresh">
                <el-option
                    v-for="item in degree_difficulty"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div v-if="knowledgeLabel.length||enterpriseLabel.length" class="label-select">
            <div class="label-text">已选择:</div>
            <div v-for="item in totalLabelSelect" :key="item.id" class="label-select-item">
              <span>{{ item.name }}</span>
              <i class="el-icon-error icon" @click="deleteLabel(item.id)" />
            </div>
            <div class="refresh-icon" @click="searchRefresh"><i class="el-icon-refresh">&nbsp;重置</i></div>
          </div>
        </div>
        <div class="classification-title">{{ course_classification_obj[questionData.courseClassification] }}</div>
        <div v-infinite-scroll="getQuestionList" class="question-list-box">
          <div v-if="questionList.length" class="question-list">
            <div v-for="item in questionList" :key="item.id" class="question-item" :class="{'question-item-select':select===item.id,'question-item-read':item.whetherRead}" @click="selectQuestion(item.id)">
              {{ item.topicContentText }}
            </div>
          </div>
          <el-empty v-else description="暂无内容" :image="require('@/assets/images/empty.png')" />
        </div>
      </aside>
      <section>
        <!--题目-->
        <div v-loading="detailLoading" class="question">
          <div class="question-header">
            <div class="title-warp">
              <div class="title" style="font-weight: bold">题干</div>
              <div style="display: flex;align-items: center">
                <dict-tag :options="degree_difficulty" :value="questionData.difficultyFactor" />
                <el-tooltip v-if="questionData.collectionOrNot" effect="light" content="取消收藏" placement="top">
                  <i class="el-icon-star-on icon" style="color: #ffa92a" @click="cancelBookMark" />
                </el-tooltip>
                <el-tooltip v-else effect="light" content="加入收藏" placement="top">
                  <i class="el-icon-star-off icon" @click="openAddCollection=true" />
                </el-tooltip>
              </div>
            </div>
            <div>
              <div class="title content ql-editor" v-html="questionData.topicContent" />
            </div>
            <div v-if="openAddCollection" class="add-collection">
              <div class="add-collection-header">
                <span>修改书签</span>
                <i class="el-icon-setting" @click="openMyBoolMark">&nbsp;设置</i>
              </div>
              <div class="add-collection-main">
                <el-select
                  v-model="bookMark"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择文章标签"
                  style="width: 80%;margin-top: 20px"
                >
                  <el-option
                    v-for="item in bookmarkList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <div style="margin-top: 50px">
                  <el-button size="small" type="primary" @click="addBookmark">提交</el-button>
                  <el-button size="small" @click="openAddCollection = false">取 消</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="question-content">
            <div v-if="questionData.showContent" class="content-box">
              <div class="content-title">解题思路</div>
              <div class="content-text  ql-editor" v-html="questionData.content" />
              <div class="content-btn-text" @click="changeShowContent">收起解题思路<i class="el-icon-arrow-up" /></div>
            </div>
            <div v-else class="content-btn" @click="changeShowContent">点击查看解题思路</div>
          </div>
          <div class="question-label">
            <div v-for="(item, index) in questionData.totalLabel" :key="index" class="question-label-item">{{ item }}</div>
          </div>
          <div v-if="previous||next" class="question-btn">
            <div v-if="previous" class="question-btn-item" @click="getQuestionDetail(previous)">上一题</div>
            <div v-if="next" style="margin-left: 14px" class="question-btn-item" @click="getQuestionDetail(next)">下一题</div>
          </div>
        </div>
        <!--评论-->
        <div class="division">讨论区</div>
        <div class="comment">
          <div class="reply">
            <el-input
              v-model="replyText"
              type="textarea"
              autosize
              maxlength="200"
              resize="none"
              placeholder="请输入内容"
            />
            <div class="reply-btn">
              <el-popover
                placement="bottom"
                width="400"
                trigger="click"
              >
                <div class="emoji-list">
                  <div v-for="(item, index) in emojiList" :key="index" class="emoji-item" @click="selectEmoji('replyText', item)">{{ item }}</div>
                </div>
                <div slot="reference" class="emoji-text"><img src="../../../assets/images/interviewQuestionBank/emoji.png" alt=""><span>表情</span></div>
              </el-popover>
              <el-button size="small" round type="primary" :loading="replyLoading" @click="sendComments('replyText')">发 送</el-button>
            </div>
          </div>
          <div class="reply-list">
            <div v-for="item in commentList" :key="item.id" class="reply-list-item">
              <div class="reply-header">
                <div class="reply-avatar-box">
                  <img :src="$downloadUrl+item.avatar" alt="">
                  <span>{{ item.name }}</span>
                </div>
                <div class="reply-time">{{ item.createdTime }}</div>
              </div>
              <div class="reply-content">
                <div>{{ item.replyContent }}</div>
                <div class="reply-btn">
                  <div class="icon-box">
                    <img
                      :src="item.likeOrNot?dianZan:zan"
                      alt=""
                      @click="handleThumbs(item)"
                    ><span>{{ item.giveLike||0 }}</span>
                  </div>
                  <div class="icon-right" @click="openReplyInput(item)">
                    <img src="../../../assets/images/interviewQuestionBank/message.png" alt="">
                    <span>{{ item.commentsNumber||0 }}</span>
                  </div>
                </div>
                <div v-if="item.showReply" class="inline-reply">
                  <div class="inline-reply-input">
                    <input
                      v-model="firstReply"
                      placeholder="发布你的评论"
                      maxlength="200"
                      size="small"
                    >
                    <el-popover
                      placement="bottom"
                      width="400"
                      trigger="click"
                    >
                      <div class="emoji-list">
                        <div v-for="(emoji, index) in emojiList" :key="index" class="emoji-item" @click="selectEmoji('firstReply', emoji)">{{ emoji }}</div>
                      </div>
                      <div slot="reference" class="emoji-text"><img src="../../../assets/images/interviewQuestionBank/emoji.png" alt=""><span>表情</span></div>
                    </el-popover>
                  </div>
                  <el-button size="small" round type="primary" :loading="replyLoading" @click="sendComments('firstReply',item.commentator,item.id,item.id)">发 送</el-button>
                </div>
                <div v-if="item.secondLevelList.length" class="second-list">
                  <div v-for="item1 in item.secondLevelList" :key="item1.id" class="second-item">
                    <div class="reply-content">
                      <div>
                        <div class="name">{{ item1.name }}</div>
                        &nbsp;<div class="center-text">回复</div>
                        <div v-if="item1.replyName" style="color: #2871EA;flex-shrink: 0;white-space: nowrap;">&nbsp;{{ item1.replyName }}</div>
                        :
                      </div>
                      <div>&nbsp;{{ item1.replyContent }}</div></div>
                    <div style="color: #999999">{{ item1.createdTime }}</div>
                    <div class="reply-btn">
                      <div class="icon-box">
                        <img
                          :src="item1.likeOrNot?dianZan:zan"
                          alt=""
                          @click="handleThumbs(item1)"
                        >
                        <span>{{ item1.giveLike||0 }}</span>
                      </div>
                      <div class="icon-right" @click="openReplyInput(item1)">
                        <img src="../../../assets/images/interviewQuestionBank/message.png" alt="">
                      </div>
                    </div>
                    <div v-if="item1.showReply" class="inline-reply">
                      <div class="inline-reply-input">
                        <input
                          v-model="secondReply"
                          placeholder="发布你的评论"
                          maxlength="200"
                          size="small"
                        >
                        <el-popover
                          placement="bottom"
                          width="400"
                          trigger="click"
                        >
                          <div class="emoji-list">
                            <div v-for="(emoji, index) in emojiList" :key="index" class="emoji-item" @click="selectEmoji('secondReply', emoji)">{{ emoji }}</div>
                          </div>
                          <div slot="reference" class="emoji-text"><img src="../../../assets/images/interviewQuestionBank/emoji.png" alt=""><span>表情</span></div>
                        </el-popover>
                      </div>
                      <el-button size="small" round type="primary" :loading="replyLoading" @click="sendComments('secondReply',item1.commentator,item.id,item1.id)">发 送</el-button>
                    </div>
                  </div>
                  <div v-if="item.commentsNumber>2" class="open-text" @click="openComment(item)">
                    {{ item.secondLevelList.length>=item.commentsNumber?'收起':'展示更多' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            v-if="total"
            style="margin: 50px 0 40px"
            background
            layout="prev, pager, next"
            :current-page.sync="pageNum"
            :total="total"
            @current-change="getCommentList"
          />
        </div>
      </section>
      <myBookMark ref="myBookMark" />
      <el-backtop target="section" />
    </div>
  </div>
</template>

<script>
import {
  addBookMark,
  addHistory,
  cancelBookmark,
  collectAdd,
  getInterviewQuestion,
  labelByStuHome,
  likeOrUnlike,
  sendComment
} from '@/api/interviewQuestionBank/interviewQuestion'
import { getCommentList, bookmarkList } from '@/api/interviewQuestionBank/interviewQuestion'
import { getInterViewQuestionDetail } from '@/api/interviewQuestionBank/interviewQuestion'
import { mapState } from 'vuex'
import myBookMark from '@/views/interviewQuestionBank/components/myBookMark'
import dictTag from "@/components/DictTag/index.vue";

export default {
  name: 'InterviewQuestionDetail',
  components: { myBookMark, dictTag },
  data() {
    return {
      firstReply: '',
      secondReply: '',
      emojiList: [
        '😀', '😁', '🤣', '😃', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '😚', '🙂', '🤗', '🤩',
        '🤔', '🤨', '😐', '😑', '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '😴', '😌',
        '😛', '😜', '😝', '🤤', '😒', '😓', '😔', '😕', '🙃', '🤑', '😲', '🙁', '😖', '😞', '😟', '😤',
        '😢', '😭', '😦', '😧', '😨', '😩', '🤯', '😬', '😰', '😱', '😳', '🤪', '😵', '😡', '😠', '🤬',
        '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '😇', '🤠', '🤡', '🤫', '🤭', '🧐', '🤓', '👽', '🤖', '💩'
      ],
      dianZan: require('@/assets/images/interviewQuestionBank/dianzan.png'),
      zan: require('@/assets/images/interviewQuestionBank/zan.png'),
      // 添加收藏
      bookMark: '',
      // 书签列表
      bookmarkList: [],
      openAddCollection: false,
      // 标签列表
      knowledgeList: [],
      enterpriseList: [],
      // 查询条件
      questionKeyWord: '',
      knowledgeLabel: [],
      enterpriseLabel: [],
      difficultyFactor: '',
      questionListLoading: false,
      questionList: [],
      questionPageNum: 1,
      questionTotal: 0,
      // 当前题目信息
      questionData: { showContent: false },
      detailLoading: false,
      courseClassification: 0,
      // 回复
      replyLoading: false,
      replyText: '',
      commentList: [],
      giveLikePadding:false,
      // 评论总数
      total: 0,
      pageNum: 1
    }
  },
  computed: {
    ...mapState({
      degree_difficulty: state => state.dict.dictTree['degree_difficulty']['arr'],
      course_classification_obj: state => state.dict.dictTree.course_classification['obj'],
      userInfo: state => state.user.userInfo
    }),
    // 显示的标签
    totalLabelSelect: {
      get() {
        return [
          ...this.knowledgeList.filter(item => this.knowledgeLabel.includes(item.id)),
          ...this.enterpriseList.filter(item => this.enterpriseLabel.includes(item.id))
        ]
      }
    },
    // 上下一个题
    previous: {
      get() {
        const index = this.questionList.findIndex(item => {
          return item.id === this.questionData.id
        })
        return index === -1 ? false : index - 1 >= 0 ? this.questionList[index - 1].id : false
      }
    },
    next: {
      get() {
        const index = this.questionList.findIndex(item => {
          return item.id === this.questionData.id
        })
        return index === -1 ? false : index + 1 < this.questionList.length ? this.questionList[index + 1].id : false
      }
    },
    // 选中题目
    select: {
      get() {
        return this.questionData.id
      }
    }
  },
  async created() {
    await this.getLabelCollection()
    await this.getQuestionDetail(this.$route.query.questionId)
    this.getBooKMark()
  },
  methods: {
    // 题目详情
    async getQuestionDetail(id) {
      if (!id) return
      this.detailLoading = true
      await getInterViewQuestionDetail(id).then(res => {
        if (res.code === 200) {
          this.questionData = res.data
          this.questionData.totalLabel = res.data.totalLabel ? res.data.totalLabel.split(',') : []
          this.questionData.showContent = false
          this.detailLoading = false
          // 修改路参数
          if (this.$route.query.questionId !== res.data.id) {
            this.$router.replace({name: 'interviewQuestionDetail', query: { ...this.$route.query, questionId: res.data.id }})
          }
          this.questionList = this.questionList.map(item => {
            return {
              ...item,
              whetherRead: item.id === res.data.id ? 1 : item.whetherRead
            }
          })
          // 添加历史记录
          addHistory({ studentId: this.userInfo.id, questionBankId: res.data.id })
          this.getCommentList()
          this.openAddCollection = false
        }
      })
    },
    // 评论
    getCommentList() {
      const obj = {
        questionBankId: this.questionData.id,
        commentLevel: 0,
        pageSize: 10,
        pageNum: this.pageNum
      }
      getCommentList(obj).then(res => {
        if (res.code === 200) {
          this.commentList = res.rows.map(item => {
            return {
              ...item,
              showReply: false,
              pageNum: 1,
              secondLevelList: item.secondLevelList ? item.secondLevelList?.map(item1 => {
                return {
                  ...item1,
                  showReply: false
                }
              }) : []
            }
          })
          this.total = res.total
        }
      })
    },
    // 查询二级评论
    openComment(item) {
      if (item.secondLevelList.length >= item.commentsNumber) {
        item.secondLevelList.splice(2)
        item.pageNum = 1
      } else {
        const obj = {
          questionBankId: this.questionData.id,
          commentLevel: 1,
          pageSize: 10,
          pageNum: item.pageNum,
          firstLevelComment: item.id
        }
        getCommentList(obj).then(res => {
          if (res.code === 200) {
            let list = res.rows.map(item => {
              return {
                ...item,
                showReply: false
              }
            })
            if (item.pageNum === 1) {
              list = list.splice(2)
            }
            item.secondLevelList.push(...list)
            item.pageNum++
          }
        })
      }
    },
    // 选中表情
    selectEmoji(content, emoji) {
      this[content] += emoji
    },
    // 点赞
    handleThumbs(item) {
      if (this.giveLikePadding) {
        return
      } else {
        this.giveLikePadding = true
      }
      // 设置点赞状态等接口完成重新设置一次
      const obj = {
        id: item.id,
        type: item.likeOrNot ? 0 : 1
      }
      likeOrUnlike(obj).then(res => {
        if (res.code === 200) {
          this.giveLikePadding = false
          item.likeOrNot = obj.type
          item.giveLike += obj.type ? 1 : -1
        }
      })
    },
    // 发送评论
    sendComments(content, id = null, firstLevelComment = null, replyCommentId = null) {
      const obj = {
        questionBankId: this.questionData.id,
        commentator: this.userInfo.id,
        replyContent: this[content],
        reply: id,
        firstLevelComment,
        replyCommentId
      }
      if (!obj.replyContent.replaceAll('\n', '').trim()) {
        this.$message.warning('评论内容不能为空')
        return
      }
      this.replyLoading = true
      sendComment(obj).then(res => {
        if (res.code === 200) {
          this[content] = ''
          this.$message.success('发送成功')
          this.getCommentList()
          this.replyLoading = false
        } else { this.replyLoading = false }
      }).catch(() => { this.replyLoading = false })
    },
    // 刷新试题列表
    async onRefresh() {
      this.questionPageNum = 1
      await this.getQuestionList()
      await this.getQuestionDetail(this.questionList[0]?.id)
    },
    // 选中题目
    selectQuestion(id) {
      this.getQuestionDetail(id)
    },
    // 试题列表
    async getQuestionList() {
      const obj = {
        studentId: this.userInfo.id,
        pageNum: this.questionPageNum,
        pageSize: 50,
        courseClassification: this.$route.query.courseClassification,
        testClassification: this.$route.query.testClassification,
        enterpriseLabel: this.enterpriseLabel?.join(',') || null,
        knowledgeTag: this.knowledgeLabel?.join(',') || null,
        difficultyFactor: this.difficultyFactor || '',
        topicContentText: this.questionKeyWord
      }
      if (this.questionPageNum > 1 && this.questionList.length >= this.questionTotal) return
      this.questionListLoading = true
      await getInterviewQuestion(obj).then(res => {
        if (res.code === 200) {
          if (this.questionPageNum === 1) {
            this.questionList = res.rows
          } else {
            this.questionList.push(...res.rows)
          }
          this.questionTotal = res.total
          this.questionPageNum += 1
          this.questionListLoading = false
        } else { this.questionListLoading = false }
      }).catch(() => { this.questionListLoading = false })
    },
    // 保存书签收藏
    addBookmark() {
      if (!this.bookMark.trim()) {
        this.$message.warning('书签不能为空')
        return
      }
      if (this.bookMark.trim().length > 30) {
        this.$message.warning('书签名称不能大于30字')
        return
      }
      this.openAddCollection = false
      const obj = {
        questionBankId: this.questionData.id,
        studentId: this.userInfo.id,
        [this.bookmarkList.some(item => item.id === this.bookMark) ? 'bookmarkId' : 'bookmarkName']: this.bookMark.trim()
      }
      collectAdd(obj).then(res => {
        if (res.code === 200) {
          if (!this.bookmarkList.some(item => item.id === this.bookMark)) {
            this.getBooKMark()
          }
          this.$message.success('加入收藏成功，可前往我的收藏页面查看。')
          this.questionData.collectionOrNot = true
          this.openAddCollection = false
        }
      })
    },
    // 取消收藏
    cancelBookMark() {
      const obj = {
        questionBankId: this.questionData.id,
        studentId: this.userInfo.id
      }
      cancelBookmark(obj).then(res => {
        if (res.code === 200) {
          this.$message.success('取消收藏成功，已从我的收藏页面移除。')
          this.questionData.collectionOrNot = false
        }
      })
    },
    // 知识点和企业标签
    async getLabelCollection() {
      const obj = {
        testClassification: this.$route.query.testClassification,
        courseClassification: this.$route.query.courseClassification
      }
      this.questionListLoading = true
      labelByStuHome(obj).then(res => {
        if (res.code === 200) {
          this.knowledgeList = res.data?.knowledgeTagList ? [...res.data.knowledgeTagList] : []
          this.enterpriseList = res.data?.enterpriseLabelList ? [...res.data.enterpriseLabelList] : []
          this.questionListLoading = false
        }
      })
    },
    // 书签
    getBooKMark() {
      const obj = { studentId: this.userInfo.id }
      bookmarkList(obj).then(res => {
        if (res.code === 200) {
          if (res.rows && res.rows.length) {
            this.bookmarkList = res.rows
            this.bookMark = ''
          } else {
            this.addBookMark()
          }
        }
      })
    },
    // 添加默认书签
    addBookMark() {
      const obj = {
        studentId: this.userInfo.id,
        name: '默认分类'
      }
      addBookMark(obj).then(res => {
        if (res.code === 200) {
          this.getBooKMark()
        }
      })
    },
    // 点击删除标签
    deleteLabel(id) {
      if (this.enterpriseLabel.includes(id)) {
        this.enterpriseLabel.splice(this.enterpriseLabel.indexOf(id), 1)
      }
      if (this.knowledgeLabel.includes(id)) {
        this.knowledgeLabel.splice(this.knowledgeLabel.indexOf(id), 1)
      }
      this.onRefresh()
    },
    // 打开书签
    openMyBoolMark() {
      this.$refs.myBookMark.show()
      this.openAddCollection = false
    },
    // 重置筛选条件
    searchRefresh() {
      this.enterpriseLabel = []
      this.knowledgeLabel = []
      this.questionKeyWord = ''
      this.questionPageNum = 1
      this.getQuestionList()
    },
    goHome() {
      const routeData = this.$router.resolve({
        name: 'home'
      })
      window.open(routeData.href)
    },
    changeShowContent() {
      this.questionData = { ...this.questionData, showContent: !this.questionData.showContent }
    },
    // 点击回复
    openReplyInput(item) {
      this.firstReply = ''
      this.secondReply = ''
      this.commentList = this.commentList.map(first => {
        return {
          ...first,
          showReply: first.id === item.id ? !first.showReply : false,
          secondLevelList: first.secondLevelList ? first.secondLevelList?.map(second => {
            return {
              ...second,
              showReply: second.id === item.id ? !second.showReply : false
            }
          }) : []
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.index{
  background-color: #edf1f7;
  .header{
    height: 69px;
    background: #FFFFFF;
    box-shadow: 0 1px 21px 0 rgba(36,36,36,0.11);
    margin-bottom: 1px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    .logo-box{
      display: flex;
      align-items: center;
      cursor: pointer;
      .logo{
        height: 38px;
        img{
          height: 100%;
        }
      }
      .name{
        font-size: 22px;
        font-weight: bold;
        color: #4C97FD;
        margin-left: 10px;
      }
    }
  }
  .main{
    display: flex;
    aside{
      flex-shrink: 0;
      width: 340px;
      background-color: white;
      height: calc(100vh - 70px);
      padding: 20px 20px 0;
      display: flex;
      flex-direction: column;
      .search-box{
        .select-box{
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
        /deep/ .el-tag{
          max-width: calc(100% - 50px)!important;
        }
      }
      .label-select{
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        align-items: center;
        margin-top: 10px;
        .label-text{
          margin-bottom: 10px;
        }
        .label-select-item{
          margin-left: 10px;
          margin-bottom: 10px;
          color: #999999;
          height: 36px;
          background: #F8F8F8;
          border-radius: 8px;
          padding: 10px;
          display: flex;
          max-width: 100%;
          span{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon{
            flex-shrink: 0;
            cursor: pointer;
            margin-left: 10px;
          }
        }
        .refresh-icon{
          color: #2871EA;
          margin-left: auto;
          user-select: none;
          cursor: pointer;
        }
      }
      .classification-title{
        margin-top: 36px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e8e8e8;
        color: #333333;
        font-size: 16px;
        text-align: left;
      }
      .question-list-box{
        margin-top: 20px;
        flex-grow: 1;
        overflow-y: auto;
        text-align: left;
        scrollbar-color:#40a0ff49 transparent;
        scrollbar-width:thin;
        &:hover{
          &::-webkit-scrollbar-thumb{
            background: #40a0ff49;
            border-radius: 10px;
          }
        }
        &::-webkit-scrollbar{
          width: 5px;
          height: 0;
        }
        // 滚动条轨道
        &::-webkit-scrollbar-track{
          background: transparent;
          border-radius: 2px;
        }
        // 小滑块
        &::-webkit-scrollbar-thumb{
          background: transparent;
          border-radius: 10px;
        }
        .question-list{
          .question-item{
            padding: 15px 0;
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover{
              cursor: pointer;
              color: #2871EA;
            }
          }
          .question-item-read{
            color: #999999;
          }
          .question-item-select{
            color: #2871EA;
          }
        }
      }
    }
    section{
      flex-grow: 1;
      padding: 20px;
      overflow-y: auto;
      height: calc(100vh - 70px);
      .question{
        background: #FFFFFF;
        border-radius: 8px;
        padding: 26px 20px;
        .question-header{
          position: relative;
          display: flex;
          flex-direction: column;
          .title-warp{
            display: flex;
            justify-content: space-between;
          }
          .title {
            padding: 0 0 0 10px;
            position: relative;
            text-align: left;
            word-break: break-all;
            overflow: auto;
            line-height: 1.5;
            &:before{
              content: '';
              position: absolute;
              width: 4px;
              height: 16px;
              background: #2871EA;
              border-radius: 2px;
              top: 4px;
              left: 0;
            }
          }
          .content {
            &:before { display: none }
          }
          i{
            flex-shrink: 0;
            margin-left: 10px;
            font-size: 20px;
            cursor: pointer;
          }
          .add-collection{
            position: absolute;
            top: 10px;
            right: 30px;
            width: 340px;
            height: 211px;
            background: #FFFFFF;
            box-shadow: 0 1px 21px 0 rgba(36,36,36,0.11);
            border-radius: 8px;
            .add-collection-header{
              font-size: 16px;
              padding: 0 20px;
              height: 48px;
              background: #2871EA;
              border-radius: 8px 8px 0 0;
              color: white;
              display: flex;
              justify-content: space-between;
              align-items: center;
              i{
                font-size: 16px;
                cursor: pointer;
              }
            }
          }
        }
        .question-content{
          margin-top: 36px;
          padding-bottom: 30px;
          border-bottom: 1px solid #e8e8e8;
          overflow: auto;
          .content-box{
            text-align: left;
            color: #333333;
            .content-title{
              position: relative;
              color: #333333;
              padding-left: 3px;
              &:before{
                position: absolute;
                top: 10px;
                left: 0;
                content: '';
                width: 10px;
                height: 10px;
                background: #2871EA;
                opacity: 0.3;
                border-radius: 50%;
              }
            }
            .content-text{
              margin-top: 20px;
              word-break: break-all;
              padding: 0;
              line-height: 1.5;
            }
            .content-btn-text{
              cursor: pointer;
              user-select: none;
              margin-top: 56px;
              color: #2871EA;
              text-align: center;
            }
          }
          .content-btn{
            cursor: pointer;
            margin: 68px auto 0;
            width: 180px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #2871EA;
            border-radius: 8px;
            line-height: 32px;
            color: #2871EA;
          }
        }
        .question-label{
          display: flex;
          margin-top: 20px;
          flex-wrap: wrap;
          .question-label-item{
            background: #F8F8F8;
            border-radius: 10px;
            margin-right: 12px;
            line-height: 20px;
            color: #999999;
            padding: 3px 16px;
            margin-bottom: 10px;
          }
        }
        .question-btn{
          margin-top: 24px;
          display: flex;
          justify-content: center;
          .question-btn-item{
            height: 28px;
            background: #F8F8F8;
            border-radius: 4px;
            padding: 6px 20px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
      .division{
        text-align: left;
        margin: 25px;
        color: #2871EA;
        font-size: 18px;
        position: relative;
        &:before{
          content: '';
          width: 16px;
          height: 4px;
          background: #2871EA;
          border-radius: 2px;
          position: absolute;
          top: 24px;
          left: 0;
        }
      }
      .comment{
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        .reply{
          background: #F8F8F8;
          border-radius: 8px;
          padding: 20px;
          .reply-btn{
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            align-items: center;
            .emoji-text{
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              img,span{
                vertical-align: middle;
              }
              span{
                margin-left: 5px;
              }
            }
          }
        }
        .reply-list{
          margin-top: 20px;
          .reply-list-item{
            margin-top: 20px;
            border-bottom: 1px #e8e8e8 solid;
            padding-bottom: 20px;
            .reply-header{
              display: flex;
              justify-content: space-between;
              .reply-avatar-box{
                img,span{
                  vertical-align: middle;
                }
                img{
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                }
                span{
                  font-size: 14px;
                  margin-left: 10px;
                }
              }
              .reply-time{
                color: #999999;
                font-size: 14px;
              }
            }
            &>.reply-content{
              text-align: left;
              margin-top: 20px;
              padding-left: 40px;
              font-size: 16px;
              word-break: break-all;
              .inline-reply{
                display: flex;
                margin-top: 10px;
                padding-right: 20px;
                height: 36px;
                align-items: center;
                .inline-reply-input{
                  height: 36px;
                  flex-grow: 1;
                  display: flex;
                  border: 1px solid #DDDDDD;
                  border-radius: 6px;
                  align-items: center;
                  padding: 0 10px;
                  input{
                    flex-grow: 1;
                    outline: none;
                    border: none;
                  }
                  .emoji-text{
                    font-size: 14px;
                    color: #333333;
                    line-height: 14px;
                    img,span{
                      vertical-align: middle;
                    }
                    span{
                      margin-left: 5px;
                    }
                  }
                }
                .el-button{
                  margin-left: 10px;
                }
              }
              &>.reply-btn{
                margin-top: 20px;
                font-size: 14px;
                display: flex;
                align-items: center;
                .icon-box, .icon-right{
                  cursor: pointer;
                  img,span{
                    vertical-align: middle;
                  }
                  span{
                    margin-left: 5px;
                  }
                }
                .icon-right{
                  margin-left: 10px;
                }
              }
              &>.second-list{
                .second-item{
                  margin-top: 12px;
                  padding-left: 10px;
                  border-left: 1px solid #f0f0f0;
                  &>div{
                    margin-top: 10px;
                  }
                  &>.reply-content{
                    display: flex;
                    align-items: baseline;
                    &>div{
                      display: flex;
                      align-items: baseline;
                    }
                    .name{
                      color: #2871EA;
                      flex-shrink: 0;
                      white-space: nowrap;
                    }
                    .center-text{
                      flex-shrink: 0;
                      white-space: nowrap;
                    }
                  }
                  &>.reply-btn{
                    display: flex;
                    align-items: center;
                    .icon-box, .icon-right{
                      cursor: pointer;
                      img,span{
                        vertical-align: middle;
                      }
                      span{
                        margin-left: 5px;
                      }
                    }
                    .icon-right{
                      margin-left: 10px;
                    }
                  }
                }
                .open-text{
                  font-size: 12px;
                  margin-top: 10px;
                  padding-left: 10px;
                  color: #2871EA;
                }
              }
            }
          }
        }
      }
    }
  }
}
.emoji-list{
  display: flex;
  flex-wrap: wrap;
  .emoji-item{
    font-size: 16px;
    width: 10%;
    text-align: center;
    padding: 10px 0;
  }
}
</style>
