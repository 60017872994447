<template>
  <el-dialog
    class="dialog-box"
    :close-on-click-modal="false"
    title="我的书签"
    :visible.sync="dialogVisible"
    width="25%"
  >
    <div v-loading="listLoading" class="bookmark-list">
      <div v-for="(item, index) in bookmarkList" :key="item.id" @mouseenter="item.showDelete=true" @mouseleave="item.showDelete=false">
        <div v-if="item.delFlag===0" class="bookmark-item">
          <div v-if="index===0" class="text-box-default">{{ item.name }}</div>
          <div v-else-if="!item.editFlag" class="text-box" @click="inputFocus(item, index)">{{ item.name }}</div>
          <el-input v-show="item.editFlag" :ref="`input${index}`" v-model="item.name" maxlength="30" placeholder="请输入内容" @keydown.native.enter="inputEnter(item)" />
          <i v-if="index>0&&item.showDelete" class="el-icon-delete" @click="deleteBookMark(index)" />
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" plain @click="addBookMark">新建</el-button>
      <div class="right">
        <el-button type="primary" @click="submitBookMark">保存</el-button>
        <el-button plain @click="dialogVisible=false">取消</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { batchSetBookMarkList, bookmarkList } from '@/api/interviewQuestionBank/interviewQuestion'
import { mapState } from 'vuex'

export default {
  name: 'MyBookMark',
  data() {
    return {
      listLoading: false,
      dialogVisible: false,
      bookmarkList: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.getMyBookMark()
    },
    addBookMark() {
      this.bookmarkList.push({
        'name': '',
        'delFlag': 0,
        'editFlag': true,
        'showDelete': false
      })
      this.$nextTick(() => {
        this.$refs[`input${this.bookmarkList.length - 1}`][0].focus()
      })
    },
    inputFocus(item, index) {
      item.editFlag = true
      this.$nextTick(() => {
        this.$refs[`input${index}`][0].focus()
      })
    },
    inputEnter(item) {
      if (item.name) {
        item.editFlag = false
      } else {
        this.$message.warning('书签名称不能为空')
      }
    },
    deleteBookMark(index) {
      console.log(this.bookmarkList)
      this.bookmarkList[index].delFlag = 1
      if (!this.bookmarkList[index]?.id) {
        this.bookmarkList.splice(index, 1)
      }
    },
    submitBookMark() {
      const obj = {
        bookmarkList: this.bookmarkList.filter(item => item.name.trim()).map(item => {
          return {
            id: item.id || null,
            studentId: this.userInfo.id,
            name: item.name.trim(),
            delFlag: item.delFlag
          }
        })
      }
      batchSetBookMarkList(obj).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.$parent.getBooKMark()
          this.dialogVisible = false
        }
      })
    },
    getMyBookMark() {
      this.listLoading = true
      const obj = { studentId: this.userInfo.id }
      bookmarkList(obj).then(res => {
        if (res.code === 200) {
          this.bookmarkList = res.rows.map(item => {
            return { ...item, editFlag: false, showDelete: false }
          })
          this.listLoading = false
        } else {
          this.listLoading = false
        }
      }).catch(() => { this.listLoading = false })
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/styles/diyDialog";
.dialog-box{
  /deep/ .el-dialog__body{
    padding-top: 10px!important;
  }
  .bookmark-list{
    min-height: 50px;
    .bookmark-item{
      margin-top: 10px;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text-box-default{
        flex-grow: 1;
        height: 34px;
        border: 1px solid transparent;
        line-height: 34px;
        padding-left: 15px;
        text-align: left;
        border-radius: 4px;
        color: #999999;
      }
      .text-box{
        flex-grow: 1;
        height: 34px;
        border: 1px solid transparent;
        line-height: 34px;
        padding-left: 15px;
        text-align: left;
        border-radius: 4px;
        &:hover{
          border: 1px solid #DCDFE6;
        }
      }
      /deep/ .el-input__inner{
        height: 36px!important;
      }
      i{
        cursor: pointer;
        font-size: 16px;
        margin-left: 17px;
      }
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: space-between;
    .el-button{
      padding: 8px 28px;
      border-radius: 8px;
      border-color: #2871EA;
      color: #2871EA;
      font-size: 14px;
    }
    .right{
      margin-left: auto;
      .el-button--primary{
        background-color: #2871EA;
        color: white;
      }
    }
  }
}
</style>
